<template>
  <Box>
    
    <ErrorRow v-if="model" :error="model._error" />
    <SavingDialog v-show="showSavingDialog"  @close="closeSavingDialog" />
    <SessionTimeoutDialog v-if="showSessionTimeoutDialog" />
    
    <LoadingRow 
      :showBorder="true" 
      v-if="isLoading">{{loadingMessage}}</LoadingRow>
      
    <Row v-else>
      <Column :width="15">
        <Box :key="redraw">
          <Row 
            v-for="(row, rowIndex) in tableData" 
            :key="rowIndex" >
            
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                 />

          </Row>
        </Box>
      </Column>

    </Row>
  </Box>
  
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import StringUtils  from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import ErrorRow from '@/components/row/ErrorRow.vue';
//import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  

import CheckList from '@/domain/model/checklist/CheckList.js';
import CheckListItem from '@/domain/model/checklist/item/CheckListItem.js';
import CheckListItemMap from '@/domain/model/checklist/item/CheckListItemMap.js';
import CheckListItemOption from '@/domain/model/checklist/item/option/CheckListItemOption.js';

import AddModel from "./AddModel.js";
import AddUtils from "./AddUtils.js";

import TitleDisplayRow from '@/portals/shared/cell/title/TitleDisplayRow.js';
import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import SavingDialog from "@/portals/shared/views/saving/SavingDialog.vue";

import SessionTimeoutDialog from "@/portals/shared/views/session/SessionTimeoutDialog.vue";

export default {
  name: "checklist-add-view",
  components: {
    ErrorRow, SavingDialog,
    Column, Box, Row, LoadingRow, 
    CellFactory, SessionTimeoutDialog,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      message: null,
      
      isLoading: true,
      loadingMessage: null,
      showSessionTimeoutDialog: false,
      
      redraw: 1,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
      
      CheckList: CheckList,
      CheckListItemMap: CheckListItemMap,
      CheckListItem: CheckListItem,
      CheckListItemOption: CheckListItemOption,
      
      CellFactory: CellFactory,
      
      tableData: [],
      addModel: null,
      timeout: null,
      
      showSavingDialog: false,
      showMissingRequirements: false,
      AndUtils: AddUtils,
      TitleDisplayRow: TitleDisplayRow,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
    itemId: function() {
      return this.$route.params['id'];
    },
    isSaving: function() {
      return this.addModel.isSaving();
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Done.value();
      this.addModel = new AddModel(this, new CheckList(this.domain));
      this.model().start();
      this.tableData = this.model().tableData();
    },
    
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    
    model: function() {
      return this.addModel;
    },
    
    withValue: function(kvp) {
      this.model().withValue(kvp);
    },
    
    withButtonPressed: function(buttonData) {
      this.model().withButtonPressed(buttonData);
    },
    
    populateData: function() {
      this.tableData = [];
      // clearTimeout(this.timeout);
      // this.timeout = setTimeout(this.reloadData, 10);
      this.reloadData();
    },
    
    
    reloadData: function() {
      this.model().populateData();
      this.tableData = this.model().tableData();
      this.redraw += 1;
    },
    
    /////////
    
    doSaveAction: function() {
      this.model.doSaveAction();
    },
    
    saving: function() {
      this.showSavingDialog = true;
    },
    
    closeSavingDialog: function() {
     this.showSavingDialog = false;  
    },
  },
}
</script>